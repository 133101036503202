export const siteData = {
    services : [
        {
            title: 'Cloud-Native Applications',
            description: `Design, development, deployment, operations and  maintenance of cloud-native
                        enterprise stacks. Fully-managed hosting solution options as well as deployment 
                        in customers AWS accounts.` 
        },
        {
            title: 'Data Management & Analytics',
            description: `Enterprise data modeling, logical and physical data integration, data warehousing, 
                        cloud-native ETL development in AWS cloud, and DW/ BI solutions.` 
        },
        {
            title: 'Digital Advertising',
            description: `Data-driven campaigns, Search Engine Optimization, full funnel management and 
                        custom design services.` 
        },

    ],
    about : { 
        description: [
            'We are a cutting edge provider of custom computer programming services and digital marketing solutions. Our team of experts is committed to delivering exceptional technology solutions that are tailored to your needs and allow you to succeed in today’s ever-changing digital landscape.', 
            'We believe that innovative technological solutions must inspire in order to have an impact. And for us, this means that we strive to inspire our clients and employees to think outside the box, dream big, and create solutions that are not only effective but also forward thinking.', 
            'Our focus on creativity drives us to find unique solutions to complex problems. We work with our clients to develop custom software, web applications, mobile apps and databases that are designed to meet their specific needs. Creativity is integral to effective marketing, and our team offers solutions such as search engine optimization (SEO), social media marketing, paid advertising, email marketing and custom content-creation.', 
            'However, above all is our committment to providing exceptional service and support to our clients, and this belief is founded in the idea that winning is essential to their success. We work closely throughout the entire project lifecycle to ensure that their needs are being met and that they are satisfied with the end result. We do not win unless you win.', 
            'We Inspire. We Create. We Win.',
            'Contact us today to learn more about how we can help you succeed.'
        ],
        sections: [
            {title: 'Inspire', bullets: [
                'We Inspire one another to be better, to reach higher, and to go further.',
                'We Inspire our clients to reach their full potential.',
                'We Inspire the world with our work.'
            ]},
            {title: 'Create', bullets: [
                'We are focused on producing value.',
                'We believe in the power of creating meaning.',                
                'We change. We innovate. We dare to create.' 
            ]},
            {title: 'Win', bullets: [
                'We are relentless in our pursuit of perfection. We do not want second place.',
                'We do not win unless our clients win.',
                'We win as a team. We lose as a team.' 
            ]}
        ]
    }
};        



