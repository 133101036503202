import React from "react";
import "./App.css";
import { Link } from "react-scroll";

import { siteData } from "./siteData";

const sections = ["Home", "Services", "About", "Contact"];

const NavLink = (props) => {
	return (
		<li>
			<Link
				activeClass="active"
				to={props.sectionName.toLowerCase()}
				spy={true}
				smooth={true}
				duration={500}
			>
				{props.sectionName}
			</Link>
		</li>
	);
};

const NavLinks = () => (
	<ul>
		{" "}
		{sections.map((section) => (
			<NavLink key={section} sectionName={section}></NavLink>
		))}{" "}
	</ul>
);

const BannerSlogan = () => {
	return (
		<span className="banner_upper">
			<span>Inspire.</span>
			<span className="ph-banner-custom">Create.</span>
			<span>Win.</span>
		</span>
	);
};

const BannerSubSlogan = () => {
	return (
		<span className="banner_lower">Enterprise IT and Marketing Solutions</span>
	);
};

const HorizontalBar = () => <span className="banner_middle"> </span>;

const ServiceOffering = (props) => {
	const sectionImageStyles = [
		"bgnd_img_programming",
		"bgnd_img_analytics",
		"bgnd_img_advertizing",
	];
	return (
		<div className="serviceOffering">
			<div
				className={`serviceImage ${sectionImageStyles[props.serviceIndex]}`}
			></div>
			<div className="services_description">
				<div className="serviceDescription_Subtitle">
					{props.offering.title}
				</div>
				{props.offering.description}
			</div>
		</div>
	);
};

export default class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mobileMenu: false,
		};
	}

	/* Hide mobile menu when touch/ click anywhere, including the menu itself, as we don't want it to hang. */
	handleMouseClick = () => {
		if (this.state.mobileMenu) {
			this.setState({ mobileMenu: false });
		}
	};

	componentDidMount = () => {
		document.addEventListener("click", this.handleMouseClick, true);
	};

	render() {
		return (
			<div className="App">
				<header className="nav">
					<div className="nav-logo"></div> {/* Company logo at the navbar */}
					<nav className="nav-links-desktop">
						<NavLinks></NavLinks>
					</nav>
					<div
						className="menuBurger"
						onClick={() =>
							this.setState({ mobileMenu: !this.state.mobileMenu })
						}
					>
						{this.state.mobileMenu ? <>&times;</> : <>&#9776;</>}
					</div>
					<nav
						className={`nav-links-mobile ${
							this.state.mobileMenu ? "expanded" : "collapsed"
						}`}
					>
						<NavLinks></NavLinks>
					</nav>
				</header>

				<section id="home" className="flex">
					<div className="banner">
						<BannerSlogan></BannerSlogan>
						<HorizontalBar></HorizontalBar>
						<BannerSubSlogan></BannerSubSlogan>
					</div>
				</section>

				<section id="services">
					<div className="sectionTitle">Services</div>
					<div className="services_container">
						{siteData.services.map((service, index) => (
							<ServiceOffering
								key={service.title}
								serviceIndex={index}
								offering={service}
							></ServiceOffering>
						))}
					</div>
				</section>

				<section id="about">
					<div className="sectionTitle">About Us</div>

					<div className="about_container">
						{siteData.about.description.map((paragraph, index) => (
							<p key={`about_${paragraph}`}>{paragraph}</p>
						))}
					</div>
				</section>

				<section id="contact">
					<div className="contactUpper">
						<div className="sectionTitle">Contact</div>
						<div className="contactUpper_row1">
							Take your organization to the
						</div>
						<div className="contactUpper_row1">next level with Inpactive</div>
						<div className="contactUpper_row2">
							<div className="contactUpper_row2_cell">
								Sent us a message!
								<br />
								<br />
								info@inpactive.com
								<br />
								<br />
								571-484-2160
							</div>
							<div className="contactUpper_row2_cell">
								NAICS Codes
								<br />
								<br />
								541511
								<br />
								512110
								<br />
								541810
								<br />
							</div>
							<div className="contactUpper_row2_cell bgnd_SBA"></div>
						</div>
					</div>

					<div className="contactLower">
						<div className="nav-logo"></div>

						<div className="contactLower_detail">
							Sterling, VA <br />
							<br />
							info@inpactive.com <br />
							<br />
							phone: 571-484-2160
						</div>

						<nav>
							<ul className="navBottom">
								{sections.map((section) => (
									<li key={`nav_bottom_${section}`}>
										<Link
											to={section.toLowerCase()}
											smooth={true}
											duration={500}
										>
											{section}
										</Link>
									</li>
								))}
							</ul>
						</nav>
					</div>
				</section>
			</div>
		);
	}
}
